// @flow

import React, { useEffect } from "react";
import { PageContent, Favourites } from "@containers";
import { graphql } from "gatsby";
import { addLocale, useLocale, t } from "ttag";
import { FavouritesBreadcrumb, Meta } from "@components";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { EVENT_MAP, type PageImpressionParams } from "../models/events";
import { TrackPageImpression } from "../components/Tracker/Tracker";
const FavouritesTemplate = ({
    data: {
        c21Translation: { translations },
        contentfulNavigation: { navigationItems },
        footerItems,
    },
    pageContext: { locale, translationSlugs, country },
}: *) => {
    addLocale(locale, translations);
    useLocale(locale);
    const gtmDispatcher = useGTMDispatch();
    useEffect(() => {
        const params: PageImpressionParams = {
            agency: null,
            agencypage: "false",
            language: locale,
            pagename: "Favourites",
            typetemplate: "favourites",
        };
        TrackPageImpression(gtmDispatcher, EVENT_MAP.PageImpression, params);
    }, []);
    return (
        <PageContent
            navigationItems={navigationItems}
            footerItems={footerItems}
            translationSlugs={translationSlugs}
            locale={locale}
            Breadcrumb={<FavouritesBreadcrumb locale={locale} />}
            country={country}
        >
            <Meta
                title={t`title.favourites`}
                description={t`meta.description.favourites`}
                translationSlugs={translationSlugs}
                locale={locale}
            />
            <Favourites locale={locale} />
        </PageContent>
    );
};

export default FavouritesTemplate;

export const query = graphql`
    query ($locale: String!, $country: String!) {
        contentfulNavigation(
            title: { eq: "Main Navigation" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        footerItems: contentfulNavigation(
            title: { eq: "Footer" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        c21Translation(locale: { eq: $locale }) {
            translations(
                keys: [
                    "api.*"
                    "slug.property-detail"
                    "favourites.*"
                    "common.*"
                    "title.favourites"
                    "meta.description.favourites"
                    "breadcrumbs.favourites*"
                    "agency.header.button.text"
                    "slug.estimation-tool"
                ]
            )
        }
    }
`;
